import React from 'react';
import ArticlePageBanner from '../components/ArticlePageBanner';
import {
    paragraph,
    paragraph2,
    paragraph3,
    randomString,
    articlePageBannerImg,
    articlePageImg,
} from '../prototypeData';
import BannerBlock from '../components/BannerBlock';
import BannerBlockData from '../components/BannerBlock.data';
import FeaturedContentBlockData from '../components/featuredContent/FeaturedContentBlock.data';
import ListBlock from '../components/ListBlock';

const ArticlePage = () => {
    return (
        <article>
            <div className="container p-0">
                <ArticlePageBanner
                    imageUrl={articlePageBannerImg}
                ></ArticlePageBanner>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-10 col-xl-8">
                        <h1>{randomString(10)}</h1>
                        <p className="lead">{paragraph}</p>
                        <p>{paragraph2}</p>
                        <h2>{randomString(5)}</h2>
                        <p>{paragraph3}</p>
                        <p>{paragraph2}</p>
                        <figure>
                            <img src={articlePageImg} alt="Alt text" />
                            <legend>{randomString(20)}</legend>
                        </figure>

                        <h2>{randomString(5)}</h2>
                        <p>{paragraph3}</p>
                        <p>{paragraph2}</p>
                        <h2>{randomString(5)}</h2>
                        <p>{paragraph3}</p>
                        <p>
                            <span className="bold">Sist oppdatert:</span>{' '}
                            19.08.2021
                        </p>
                    </div>
                </div>
            </div>
            <div className="container container--xxl p-0">
                <BannerBlock {...BannerBlockData} />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-10 col-xl-8">
                        <ListBlock {...FeaturedContentBlockData} />
                    </div>
                </div>
                <br />
                <br />
            </div>
        </article>
    );
};

export default ArticlePage;
