import React from 'react';
import { ButtonEnum } from '../../shared/lib/types';

export interface IBannerBlock {
    imageUrl: string;
    text: string;
    buttonText: string;
    buttonUrl: string;
}

const BannerBlock = (props: IBannerBlock) => (
    <div className="banner-block">
        <div
            className="banner-block__image-wrapper"
            style={{ backgroundImage: `url(${props.imageUrl})` }}
        ></div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-10 col-xxl-12">
                    <h2>{props.text}</h2>
                    <a
                        href={props.buttonUrl}
                        className={ButtonEnum.Secondary + ' btn--inverted'}
                    >
                        {props.buttonText}
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default BannerBlock;
