import { IBannerBlock } from './BannerBlock';
import { randomString, bannerBlockImg } from '../prototypeData';

export default {
    text: randomString(15),
    buttonText: randomString(4),
    buttonUrl: '/',
    imageUrl: bannerBlockImg,
    applyFilter: true,
} as IBannerBlock;
